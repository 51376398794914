import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import styled from "styled-components"

const Title = styled.h1`
  margin-right: 16px;
`

const HeadingSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

const ApplyButton = styled.a`
  background: #01bdbe;
  background: linear-gradient(135deg, #01bdbe 0%, #00eac5 100%);
  border: none;
  color: #fff;
  padding: 6px 16px;
  border-radius: 8px;
  margin-left: 16px;
  display: inline-block;
`

const Apply = styled.div`
  border-top: 1px solid #e7e7ec;
  margin-top: 32px;
  padding-top: 32px;
`

export default ({
  data: {
    job: {
      childMarkdownRemark: { frontmatter, html },
    },
  },
}) => (
  <Layout
    meta={{
      title: frontmatter.meta.title,
      description: frontmatter.meta.description,
    }}
  >
    <section>
      <div className="container">
        <div className="row">
          <div className="sub-page-heading">
            <HeadingSection>
              <Title>{frontmatter.job.title}</Title>
              <div>
                {frontmatter.job.location}{" "}
                {frontmatter.open ? (
                  <ApplyButton href="#apply">Apply</ApplyButton>
                ) : null}
              </div>
            </HeadingSection>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="row">
          <div
            className="blog-text"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          {frontmatter.open ? (
            <Apply id="apply">
              <h6>Does this sound like you?</h6>
              <p>
                Email your application to{" "}
                <a href="mailto:talent@creativethinkinginstitute.com">
                  talent@creativethinkinginstitute.com
                </a>
              </p>
            </Apply>
          ) : null}
        </div>
      </div>
    </section>
  </Layout>
)

export const jobQuery = graphql`
  query JobQuery($name: String!) {
    job: file(relativeDirectory: { eq: "jobs" }, name: { eq: $name }) {
      childMarkdownRemark {
        html
        frontmatter {
          open
          slug
          title
          job {
            location
            team
            title
          }
          meta {
            title
            description
          }
        }
      }
    }
  }
`
